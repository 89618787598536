<template>
  <LoadingComponent />
</template>

<script>
import LoadingComponent from '@/components/misc/LoadingComponent.vue';

export default {
  name: 'OfferRedirectPatchView',
  components: {
    LoadingComponent,
  },
  mounted() {
    this.$nextTick(() => {
      this.$router.push({
        name: 'candidate:offer',
        params: {
          geo: 'CL',
          uuid: this.$route.params.uuid,
        },
      });
    });
  },
};
</script>
